import { render, staticRenderFns } from "./emailtransactioninvoice.html?vue&type=template&id=52514bba&scoped=true&"
import script from "./emailtransactioninvoice.vue?vue&type=script&lang=js&"
export * from "./emailtransactioninvoice.vue?vue&type=script&lang=js&"
import style0 from "./emailtransactioninvoice.css?vue&type=style&index=0&id=52514bba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52514bba",
  null
  
)

export default component.exports