<template src="./emailtransactioninvoice.html"></template>
<style scoped src="./emailtransactioninvoice.css"></style>
<script>
import TransactionService from '@/services/TransactionService.vue'
import AccountService from '@/services/AccountService.vue'
import Loading from 'vue-loading-overlay'
import router from '@/router/index.js'
import { required } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'
import utils from '@/utils/utils.vue'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    components : {
       Loading, modal, pagination
    },
    data () {
        return {
            searchBy : 'senderEmail',
            searchValue:'',
            country:null,
            customerEmail:null,
            fundingSource:null,
            parentReferenceNumber :null,
            referenceNumber: null,
            transactionDate:null,
            transactionDateTo:null,
            transactionId:null,
            transactionStatus:'1',
            transactionType : '3',
            resdata : [],
            errorMessages: applicationConstants.formsMessages,
            columns :['Select','DateTime','TransactionId','ParentReferenceNumber','SourceCurrency','SenderEmail','SenderName','TargetAmount','TargetCurrency','TransactionType'],
            options : {
                headings : {
                   TransactionId: 'Transaction Id',
                   ParentReferenceNumber : 'Parent Reference Number',
                   SourceCurrency : 'Source Currency',
                   SenderEmail : 'Customer Email',
                   SenderName : 'Customer Name',
                   TargetAmount : 'Target Amount',
                   TargetCurrency : 'Target Currency',
                   TransactionType: 'Transaction Type'
                }
            },
            loaderVisible: false,
            selectedRow : [],
            tDetails: [],
            userDetails : {},
            transactionDetails : {},
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            isLazyLoadingEnabled: applicationConstants.enableLazyLoading
        }
    },
    methods : {
        async show() {
            try {
                this.$v.searchValue.$touch()
                if(this.$v.searchValue.$invalid){
                    return
                }
                this.loaderVisible = true
                this.customerEmail = this.searchBy === 'senderEmail' ? this.searchValue : null
                this.parentReferenceNumber = this.searchBy === 'parentReferenceNumber' ? this.searchValue : null
                this.referenceNumber = this.searchBy === 'referenceNumber' ? this.searchValue : null
                this.transactionId =this.searchBy === 'transactionId' ? this.searchValue : null
                await TransactionService.methods.GetTransactionSummary({
                    CustomerEmail : this.customerEmail,
                    ParentReferenceNumber : this.parentReferenceNumber,
                    ReferenceNumber: this.referenceNumber,
                    TransactionId:this.transactionId,
                    TransactionStatus: this.transactionStatus,
                    TransactionType : this.transactionType
                })
                .then(res => {
                    this.responseTransactionDataCheck(res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem)
                    // this.resdata = this.responsemapping(this.resdata)
                    // this.resdata.forEach(item => item.DateTime = utils.methods.UTCtoLocal(moment(moment(item.DateTime, 'MM/DD/YYYY hh:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss')))
                })
                   .catch(err =>{
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                   })
                this.loaderVisible= false
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async showAll() {
            try {
                this.$v.$reset()
                this.customerEmail = null
                this.parentReferenceNumber = null
                this.referenceNumber = null
                this.transactionId = null
                this.searchValue=''
                this.loaderVisible=true
                await TransactionService.methods.GetTransactionSummary({
                   TransactionType: this.transactionType,
                   TransactionStatus : this.transactionStatus
                })
                .then(res => {
                    this.responseTransactionDataCheck(res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem)
                    // this.resdata = this.responsemapping(this.resdata)
                    // this.resdata.forEach(item => item.DateTime = utils.methods.UTCtoLocal(moment(moment(item.DateTime, 'MM/DD/YYYY hh:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss')))
                })
                .catch(err =>{
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                this.loaderVisible=false   
            } catch (error) {
               //
            }
        },
        responseTransactionDataCheck(resData) {
            if(resData) {
                this.resdata = Array.isArray(resData) ? resData : [resData]
            }else{
                this.resdata = []
            }
            if(this.isLazyLoadingEnabled) {
                this.$refs.paginationRef.selectedPage = 1
            }
        },
        async sendInvoice() {
            try {
                if (this.selectedRow.length > 0) {
                    this.selectedRow.forEach(element => {
                        TransactionService.methods.GetTransactionDeatils(element)
                        .then(res => {
                            this.tDetails = res.csTransactionDetailsResponse.TransactionData
                            this.transactionDetails.dateTime = this.tDetails.DateTime._text ? this.tDetails.DateTime._text : null
                            this.transactionDetails.TransactionNumber = element.TransactionId
                            this.transactionDetails.ReferenceNumber = this.tDetails.ReferenceNumber._text ? this.tDetails.ReferenceNumber._text :null
                            this.transactionDetails.SendAmount = this.tDetails.RequestedAmount._text ? this.tDetails.RequestedAmount._text : null
                            this.transactionDetails.ReceiveAmount = this.tDetails.DestAmount._text ? this.tDetails.DestAmount._text : null
                            this.transactionDetails.name = this.tDetails.RecipientFirstName._text ? this.tDetails.RecipientFirstName._text : null + this.tDetails.RecipientLastName._text ? this.tDetails.RecipientLastName._text : null 

                            AccountService.methods.GetAccountDetails(element.CustomerEmail)
                            .then(rep => {
                                this.userDetails.city = rep.csGetUserProfileResponse.City._text ? rep.csGetUserProfileResponse.City._text : null
                                this.userDetails.StreetAddress1 = rep.csGetUserProfileResponse.StreetAddress1._text ? rep.csGetUserProfileResponse.StreetAddress1._text : null
                                this.userDetails.StreetAddress2 = rep.csGetUserProfileResponse.StreetAddress2._text ? rep.csGetUserProfileResponse.StreetAddress2._text : null
                                this.userDetails.Country = rep.csGetUserProfileResponse.Country._text ? rep.csGetUserProfileResponse.Country._text : null
                            })
                            .catch(err => {
                                this.alertObject = err
                                this.$bvModal.show(this.$refs.childModal.id)
                            })
                        })
                        .catch(err => { 
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                    })
                }else{
                    this.showAlert(null, 'Please select one')
                }
            } catch (error) {
                //
            }
        },
        check(transactionId,email,checked) {
            try {
                let obj = {
                TransactionId : transactionId,
                CustomerEmail : email
                }
                if(checked){
                        this.selectedRow.push(obj)
                    }else{
                    let uncheckedItem = this.selectedRow.findIndex(val => val.TransactionId === transactionId)
                    this.selectedRow.splice(uncheckedItem,1)
                }
            } catch (error) {
                //
            }
        },
        responsemapping(data) {
            if(data !== undefined  && data !== null)  {
                if(!Array.isArray(data)){
                    data=[data]
                }
                for (const [key,value] of Object.entries(data)) {
                    for (const [_key,_value] of Object.entries(value)){
                            data[key][_key]=null 
                        for (const [_key1,_value1] of Object.entries(_value)){
                            data[key][_key]=_value1
                        }
                    }
                } 
            }else{
                 data=data? data : []
            }
            return data
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        goToMain(){
            router.push('Dashboard')
        }
    },
    computed: {
        dataTable() {
            let tranSummary = []
            if(this.resdata && this.resdata.length > 0) {
                tranSummary = JSON.parse(JSON.stringify(this.resdata))
                if(this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    tranSummary = tranSummary.slice((selectedPage - 1)* 10, selectedPage * 10)
                }
                tranSummary = this.responsemapping(tranSummary)
                tranSummary.forEach(item => item.DateTime = utils.methods.UTCtoLocal(moment(moment(item.DateTime, 'MM/DD/YYYY hh:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss')))
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.resdata?.length ?? 0
                }
            }
            this.isLazyLoadingEnabled ? updateTotalRecords() : null
            return tranSummary
        }
    },
    validations : {
        searchValue : {
            required
        }
    }
}
</script>